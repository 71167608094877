import React from "react";
import NewAccordion from "components/JobAccordion/NewAccordion";
import { Link } from "react-router-dom";

const NewFeaturedJob = ({ setJobs }) => {
  return (
    <>
      <div className="flex-wrap mt-10 md:flex md:mt-0 justify-evenly items-center py-10 bg-blue-50 px-10 border-black">
        <div className="text-center">
          <div className="text-xl font-semibold md:text-[36px] text-blue-900">
            Featured Jobs
          </div>
          <div className="text-sm text-gray-500 md:mt-2 text-blue-900">
            Search your career opportunities through various jobs
          </div>
        </div>
        <div className="mt-2 md:mt-0 flex item-center justify-center">
          <Link to="/jobboard">
            <button className="blueFatButton px-3 py-2 sm:px-4 sm:py-3 md:px-6 md:py-4 bg-blue-500 text-white text-sm sm:text-base md:text-lg rounded-md hover:bg-blue-600">
              View All Jobs
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-blue-50 py-10">
        {/* <div className="center-align"> */}
        <NewAccordion setJobs={setJobs} />
        {/* </div> */}
      </div>
    </>
  );
};

export default NewFeaturedJob;

import React from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useAuth } from "utils/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Navbar_List } from "./constants";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  MenuIcon,
  KeyboardArrowUpIcon,
} from "components/MUI";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"; // import companyLogo from "../../images/companyLogo.png";
import newLogo from "../../images/Landing/newLogo.png";
import NewSignup from "pages/auth/NewSignup";
import NewLogin from "pages/auth/NewLogin";

const activePageDetector = (window) => {
  const pathName = window.location.pathname;
  switch (pathName) {
    case "/":
      return "Home";
    case "/candidate/dashboard":
    case "/recruiter/dashboard":
    case "/admin/dashboard":
      return "Dashboard";
    case "/admin/jobs":
      return "Jobs";
    case "/admin/recruiters":
      return "Recruiter";
    case "/admin/companies":
      return "Company";
    case "/postjob":
      return "Post Job";
    case "/contactus":
      return "Contact Us";
    default:
      return null;
  }
};

const Candidatelinks = [
  { name: "Home", link: "/" },
  { name: "Dashboard", link: "/candidate/dashboard" },
  { name: "Jobs", link: "/jobboard" },
  {
    name: "Blog",
    link: "https://blog.workcrew.ai/",
    target: "_blank",
    href: true,
  },
  { name: "Contact Us", link: "/contactus" },
];
const Recruiterlinks = [
  { name: "Home", link: "/", target: "" },
  { name: "Dashboard", link: "/recruiter/dashboard", target: "" },
  { name: "Post Job", link: "/postjob", target: "" },
  {
    name: "Blog",
    link: "https://blog.workcrew.ai/",
    target: "_blank",
    href: true,
  },
  { name: "Contact Us", link: "/contactus", target: "" },
];
const Adminlinks = [
  { name: "Home", link: "/" },
  { name: "Dashboard", link: "/admin/dashboard" },
  { name: "Jobs", link: "/admin/jobs" },
  { name: "Recruiter", link: "/admin/recruiters" },
  { name: "Company", link: "/admin/companies" },
  {
    name: "Blog",
    link: "https://blog.workcrew.ai/",
    target: "_blank",
    href: true,
  },
  // { name: "Contact Us", link: "/contactus" },
];
const PublicLinks = [
  { name: "Home", link: "/" },
  { name: "Jobs", link: "/jobboard" },
  {
    name: "Blog",
    link: "https://blog.workcrew.ai/",
    target: "_blank",
    href: true,
  },
  { name: "Contact Us", link: "/contactus" },
];
const linkGen = (array) => {
  return array.map((link) => {
    if (link.href)
      return (
        <a
          href={link.link}
          key={link.name}
          target={link.target}
          className="list cursor-pointer hover:text-primarycolor pr-10 text-white mr-8 hover:underline hover:underline-offset-[12px] decoration-2"
        >
          {link.name}
        </a>
      );
    else
      return (
        <Link
          to={link.link}
          key={link.name}
          target={link.target}
          className={`list ${
            activePageDetector(window) === link.name
              ? "underline underline-offset-[12px] text-primarycolor "
              : ""
          }cursor-pointer hover:text-primarycolor text-white pr-10 mr-8 [&:last-child]:mr-0 sm:[&:last-child]:mr-3 hover:underline hover:underline-offset-[12px] decoration-2`}
        >
          {link.name}
        </Link>
      );
  });
};

const NewNavbar = ({ disableLogo }) => {
  const auth = useAuth();
  const userType = auth.userType;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleLoginClick = () => {
    setShowLoginPopup(true);
  };

  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const handleSignupClick = () => {
    setShowSignupPopup(true);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.logout();
    // navigate('/login');
    // setShowLoginPopup(true);
    navigate("/");
  };
  const handleCandidateProfile = () => {
    navigate("/candidate/profile");
  };
  const handleRecruiterProfile = () => {
    navigate("/recruiter/profile");
  };
  const handleAdminProfile = () => {
    console.log("admin");
    navigate("/recruiter/profile");
  };

  // const toSignup = () => {
  //   navigate('/signup');
  // };

  const [humburger, setHumburger] = useState(true);

  const handleHumburgerClick = () => {
    setHumburger(!humburger);
  };

  const MenuComponent = ({ children }) => {
    return (
      <>
        <div>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {children}
          </Menu>
        </div>
      </>
    );
  };

  const ProfileAvatar = () => {
    return (
      <>
        <Button
          disableRipple
          elevation={0}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            backgroundColor: "transparent",
            padding: "0px",
          }}
        >
          <Avatar
            className="btn navbar__avatar w-8 h-8"
            src="components/images/avatar.png"
            // style={{'zoom': '100%'}}
          />
        </Button>
      </>
    );
  };

  const handleMobileOpen = () => {
    setMobileView((prev) => !prev);
  };
  const availableLinks = (value) => {
    switch (value) {
      case "candidate":
        return linkGen(Candidatelinks);
      case "recruiter":
        return linkGen(Recruiterlinks);
      case "admin":
      case "super_admin":
        return linkGen(Adminlinks);
      default:
        return linkGen(PublicLinks);
    }
  };
  const mobileHamburgerHide = (value) => {
    switch (value) {
      case "candidate":
      case "recruiter":
      case "admin":
      case "super_admin":
        return (
          <>
            <div className="mobile flex md:hidden mr-2.5 items-center">
              <MenuIcon className="cursor-pointer" onClick={handleMobileOpen} />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const availableUser = (value) => {
    // console.log(value);
    switch (value) {
      case "candidate":
        return (
          <>
            <div>
              {ProfileAvatar()}
              <MenuComponent>
                <MenuItem onClick={handleCandidateProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuComponent>
            </div>
          </>
        );
      case "recruiter":
        return (
          <>
            <div>
              {ProfileAvatar()}
              {/* <ProfileAvatar /> */}

              <MenuComponent>
                <MenuItem onClick={handleRecruiterProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuComponent>
            </div>
          </>
        );
      case "admin":
      case "super_admin":
        return (
          <>
            <div>
              {/* <ProfileAvatar /> */}
              {ProfileAvatar()}
              <MenuComponent>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuComponent>
            </div>
          </>
        );
      default:
        return (
          <button className="btn bg-primarycolor rounded-sm border-none font-bold text-white py-2 px-8 cursor-pointer">
            <span onClick={handleLoginClick}>Login</span>/
            <span onClick={handleSignupClick}>Signup</span>
          </button>
        );
    }
  };

  const renderedLinks = availableLinks(userType);
  const profileMenu = availableUser(userType);
  const hamburgerMenu = mobileHamburgerHide(userType);
  const [mobileView, setMobileView] = React.useState(false);
  const handleMobileClose = () => {
    setMobileView((prev) => !prev);
  };

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from(".logo", {
      y: -20,
      opacity: 0,
      duration: 1,
      delay: 0.2,
    });

    tl.from(".list", {
      y: -20,
      opacity: 0,
      stagger: 0.2,
    });

    tl.from(".btn", {
      y: -20,
      opacity: 0,
      stagger: 0.2,
    });
  });

  return (
    <>
      {mobileView ? (
        <>
          <div className="mobile-view bg-white h-screen w-[100%] fixed z-20">
            <div className="flex flex-col gap-5 p-10">
              <div className="">
                <CloseRoundedIcon
                  size="medium"
                  className="cursor-pointer"
                  onClick={handleMobileClose}
                />
              </div>
              <div className="flex flex-col gap-8 ">{renderedLinks}</div>
              {/* <img src={companyLogo} /> */}
              {/* {profileMenu} */}
            </div>
          </div>
        </>
      ) : (
        <div className="right-align md:m-0 md:w-full font-sans w-[100%] bg-landing">
          {/* removed px-2 */}
          <div
            className={`flex py-2 xs:px-5 pt-2 justify-between ${
              disableLogo ? "md:pr-0" : ""
            }`}
          >
            {hamburgerMenu}
            <div
              className={`flex space-between flex-grow mx-12 mt-2 ${
                disableLogo ? "justify-center" : "justify-between"
              }`}
            >
              {!disableLogo && (
                <div className="logo flex items-center flex-1 md:flex justify-between">
                  <Link to="/">
                    <img src={newLogo} alt="" className="logo w-[147px]" />
                  </Link>
                </div>
              )}
              <div className="items-center desktop hidden md:flex ">
                <div className="links ml-2 sm:ml-0 whitespace-nowrap hidden lg:flex ">
                  {renderedLinks}
                </div>
              </div>
              {!disableLogo && profileMenu}
            </div>
            {disableLogo && <div className="flex-shrink-0">{profileMenu}</div>}
          </div>
        </div>
      )}
      {showSignupPopup && (
        <NewSignup
          setShowSignupPopup={setShowSignupPopup}
          setShowLoginPopup={setShowLoginPopup}
        />
      )}
      {showLoginPopup && (
        <NewLogin
          setShowLoginPopup={setShowLoginPopup}
          setShowSignupPopup={setShowSignupPopup}
        />
      )}

      {/* <div className="justify-center">nice</div> */}
    </>
  );
};

export default NewNavbar;
